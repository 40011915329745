import { useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import Input from "../../ui/input/Input";
import Button from "../../ui/button/Button";
import strings from "../../i18n/i18nStrings";
import styles from "./LoginModal.module.scss";
import { useNavigate } from "react-router-dom";
import { NAVIGATION_IDS } from "../common/constants/navigation";
import Modal from "../../ui/modal/Modal";

const LoginModal = ({
    isOpen,
    onClose
} : {
    isOpen: boolean;
    onClose: () => void;
}) => {
    const { signIn } = useAppContext();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isCreatingAccount, setIsCreatingAccount] = useState(false);

    // const toggleCreateAccount = (headingToCreateAccount: boolean) => {
    //     setEmail('');
    //     setPassword('');
    //     setIsCreatingAccount(headingToCreateAccount);
    // }

    const onSignIn = () => {
        signIn(email, password, () => {
            navigate(NAVIGATION_IDS.DASHBOARD);
        });
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isThin
        >
            <div>
                {
                    isCreatingAccount ? (
                        <div>Creating Account</div>
                    ) : (
                        <>
                            <div className={styles.marginedInput}>
                                <Input
                                    text={email}
                                    onChange={(enteredUsername) => setEmail(enteredUsername)}
                                    label={strings.loginScreen.emailLogin}
                                />
                            </div>
                            <Input
                                text={password}
                                onChange={(enteredPassword) => setPassword(enteredPassword)}
                                hideable
                                label={strings.loginScreen.password}
                            />
                            <div className={styles.loginContainer}>
                                <div className={styles.loginButton}>
                                    <Button
                                        onPress={onSignIn}
                                        disabled={!email || !password}
                                    >
                                        <span>{strings.common.submit}</span>
                                    </Button>
                                </div>
                                <div className={styles.needAccountContainer}>
                                    <span>{strings.loginScreen.needAccountPt1}</span>
                                    <span>{strings.loginScreen.needAccountPt2}</span>
                                    <span>{strings.loginScreen.needAccountPt3}</span>
                                    <span>{strings.loginScreen.needAccountPt4}</span>
                                    <span>{strings.loginScreen.needAccountPt5}</span>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </Modal>
    )
}

export default LoginModal;