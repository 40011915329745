import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider } from './contexts/AppContext';
import Navigation from './pages/navigation/Navigation';
import { FilterContextProvider } from './contexts/FilterContext';
// import { MessageCenterContextProvider } from './contexts/MessageCenterContext';
import { ClerkProvider } from '@clerk/clerk-react';

const PUBLISHABLE_KEY = 'pk_test_d2lubmluZy1hcmFjaG5pZC04OC5jbGVyay5hY2NvdW50cy5kZXYk';

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
).render(
  <ClerkProvider
    publishableKey={PUBLISHABLE_KEY}
    afterSignOutUrl="/"
  >
    <AppContextProvider>
      <FilterContextProvider>
        {/* <MessageCenterContextProvider> */}
        <React.StrictMode>
          {/* <RouterProvider router={router} /> */}
          <Navigation />
        </React.StrictMode>
        {/* </MessageCenterContextProvider> */}
      </FilterContextProvider>
    </AppContextProvider>
  </ClerkProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
