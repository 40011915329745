import { OptionType } from "../constants";
import { useState } from "react";
import Label from '../label/Label';
import styles from './RadioButtonGroup.module.scss';

const RadioButtonGroup = ({
    options,
    initialOptionSelected,
    onSelect,
    label = '',
}: {
    options: OptionType[];
    initialOptionSelected?: OptionType;
    onSelect: (option: OptionType) => void;
    label?: string;
}) => {
    const [selectedOption, setSelectedOption] = useState(initialOptionSelected || options[0]);

    const handleOptionPress = (option: any) => {
        setSelectedOption(option);
        onSelect(option);
    };

    return (
        <div className={styles.container}>
            { label && <Label label={label} /> }
            {options.map((option) => (
                <div
                    key={option.id}
                    className={styles.radioButtonOption}
                >
                    <div
                        className={`
                            ${styles.radioButton}
                            ${selectedOption.id === option.id && styles.radioButtonSelected}
                        `}
                        onClick={() => handleOptionPress(option)}
                    />
                    <span>{option.name}</span>
                </div>
            ))}
        </div>
    );
}

export default RadioButtonGroup;