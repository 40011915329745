import { useEventsContext } from "../../contexts/EventsContext";
import strings from "../../i18n/i18nStrings";
import styles from "./EventDisplayList.module.scss";
import Filters from "../filters/Filters";
import Button, { BUTTON_VARIANTS } from "../../ui/button/Button";
import Modal from "../../ui/modal/Modal";
import CreateOrEditEvent from "../../i18n/components/createOrEditEvent/CreateOrEditEvent";
import { useState } from "react";
import BackHeader from "../mobile/backHeader/BackHeader";
import Loader from "../loader/Loader";
import EmptyDisplayList from "./emptyDisplayList/EmptyDisplayList";
import EventDisplayListItem from "./eventDisplayListItem/EventDisplayListItem";

const EventDisplayList = () => {
    const {
        events,
        eventsLoading,
        fetchEvents,
    } = useEventsContext();

    const [showCreateEvent, setShowCreateEvent] = useState(false);

    const onEventCreation = () => setShowCreateEvent(true);
    const onCancelEventCreation = () => setShowCreateEvent(false);

    return (
        <>
            <div className={styles.sidebarContainer}>
                <div className={`${styles.eventsListContainer} ${showCreateEvent ? styles.createModeActive : ''}`}>
                    <div className={styles.filtersContainer}>
                        <Filters />
                    </div>
                    <div className={styles.eventsContainer}>
                        {
                            eventsLoading ? (
                                <Loader />
                            ) : events.length ? (
                                <div className={styles.eventsContainerDisplayItems}>
                                    {events.map((event, idx) => (
                                        <EventDisplayListItem event={event} />
                                    ))}
                                </div>
                            ) : (
                                <EmptyDisplayList
                                    onCtaClick={onEventCreation}
                                />
                            )
                        }
                    </div>
                    <div className={styles.createButtonContainer}>
                        <Button
                            onPress={onEventCreation}
                            roleVariant={BUTTON_VARIANTS.CTA}
                        >
                            <span>{strings.createOrEditEventScreen.createEvent}</span>
                        </Button>
                    </div>
                </div>
                {
                    showCreateEvent && (
                        <div className={styles.createEventMobile}>
                            <>
                                <BackHeader
                                    onBack={() => setShowCreateEvent(false)}
                                    backText={strings.eventDetailsScreen.backtoEvents}
                                />
                                <div className={styles.createForm}>
                                    <CreateOrEditEvent
                                        onCancel={onCancelEventCreation}
                                        onSuccess={fetchEvents}
                                    />
                                </div>
                            </>
                        </div>
                    )
                }
            </div>
            <div className={styles.createEventModal}>
                <Modal
                    isOpen={showCreateEvent}
                    onClose={onCancelEventCreation}
                >
                    <CreateOrEditEvent
                        onCancel={onCancelEventCreation}
                        onSuccess={fetchEvents}
                    />
                </Modal>
            </div>
        </>
    )
}

export default EventDisplayList;