import { useState } from 'react';
import Button, { BUTTON_VARIANTS } from '../../ui/button/Button';
import styles from './Filters.module.scss';

const Filters = () => {
    const [showFilters, setShowFilters] = useState(false);

    return (
        <div className={styles.filters}>
            <Button
                roleVariant={BUTTON_VARIANTS.GHOST}
                onPress={() => setShowFilters(true)}
            >
                <span>Filters</span>
            </Button>
            {
                showFilters && <div>Filters</div>
            }
        </div>
    )
}

export default Filters;