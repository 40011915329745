import Button, { BUTTON_VARIANTS } from '../../../ui/button/Button';
import { LAMP_POST_COLORS } from '../../../ui/constants';
import { ReactComponent as ChevronIcon } from '../../../assets/icons/ChevronIcon.svg';
import styles from './BackHeader.module.scss';

const BackHeader = ({
    onBack,
    backText
}: {
    onBack: () => void;
    backText: string;
}) => (
    <div className={styles.backToContainer}>
        <Button
            roleVariant={BUTTON_VARIANTS.GHOST}
            onPress={onBack}
        >
            <div className={styles.backToContents}>
                <ChevronIcon
                    width={22}
                    fill={LAMP_POST_COLORS.oliveGreen}
                    className={styles.backChevron}
                />
                {backText}
            </div>
        </Button>
    </div>
);

export default BackHeader;