import {
    Route,
    Navigate,
    Routes,
    BrowserRouter
} from 'react-router-dom';
import HomePage from '../homePage/HomePage';
import DashboardPage from '../dashboardPage/DashboardPage';
import { useAppContext } from '../../contexts/AppContext';
import EventsPage from '../eventsPage/EventsPage';
import { NAVIGATION_IDS } from '../../components/common/constants/navigation';
import AboutPage from '../about/AboutPage';
import FaqPage from '../faq/FaqPage';
import LegalPage from '../legal/LegalPage';
import { EventsContextProvider } from '../../contexts/EventsContext';

const Navigation = () => {
    const { isSignedIn } = useAppContext();

    return (
        <BrowserRouter>
            <EventsContextProvider>
                <Routes>
                    <Route
                        path='/'
                        element={<HomePage />}
                    />
                    <Route
                        path={NAVIGATION_IDS.FRONTPAGE}
                        element={<HomePage />}
                    />
                    <Route
                        path={NAVIGATION_IDS.ABOUT}
                        element={<AboutPage />}
                    />
                    <Route
                        path={NAVIGATION_IDS.FAQ}
                        element={<FaqPage />}
                    />
                    <Route
                        path={NAVIGATION_IDS.LEGAL}
                        element={<LegalPage />}
                    />
                    {
                        isSignedIn && (
                            <>
                                <Route
                                    path={NAVIGATION_IDS.LOGIN}
                                    element={<div>Log In</div>}
                                />
                                <Route
                                    path={NAVIGATION_IDS.DASHBOARD}
                                    element={<DashboardPage />}
                                />
                                <Route
                                    path={NAVIGATION_IDS.EVENTS}
                                    element={<EventsPage />}
                                />
                            </>
                        )
                    }
                    <Route
                        path='*'
                        element={<Navigate to="/" replace />}
                    />
                </Routes>
            </EventsContextProvider>
        </BrowserRouter>
    )
}

export default Navigation;
