import strings from "../../i18n/i18nStrings";
import PageLayout from "../common/PageLayout";
import styles from "./AboutPage.module.scss";

const AboutPage = () => (
    <PageLayout>
        <div className={styles.aboutUsContainer}>
            {/* <p className={styles.aboutUsTitle}>{strings.aboutUs.title}</p> */}
            {
                Object.values(strings.aboutUs.sections).map((aboutUsInfo) => (
                    <div>
                        <p className={styles.aboutUsHeader}>{aboutUsInfo.title}</p>
                        {Object.values(aboutUsInfo.paragraphs).map(aboutUsParagraph => (
                            <p>{aboutUsParagraph}</p>
                        ))}
                    </div>
                ))
            }
        </div>
    </PageLayout>
);

export default AboutPage;