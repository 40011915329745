const strings = {
    common: {
        pages: {
            about: 'About Us',
            faq: 'FAQs',
            legal: 'Legal',
        },
        copyright: '© 2024 LampPost. All rights reserved.',
        dropdownPlaceholder: {
            single: 'Select Option',
            multi: 'Select Options',
            multiSelected: (amount: string) => `Selected [${amount}]`
        },
        lampPost: 'LampPost',
        back: 'Back',
        cancel: 'Cancel',
        clear: 'Clear',
        next: 'Next',
        previous: 'Previous',
        clearFilters: 'Clear Filters',
        submit: 'Submit',
        update: 'Update',
        filters: 'Filters',
        showLess: 'Show less',
        photos: 'Photos',
        preview: 'Preview',
        edit: 'Edit',
        delete: 'Delete',
        logOut: 'Log out',
        filterTypes: {
            host: 'Event Host',
            name: 'Event Name',
            location: 'Location',
            distance: 'Distance',
            dateTime: 'Date / Time',
            cost: 'Cost',
            tags: 'Tags',
        },
        repeats: {
            none: 'Non-repeating',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            yearly: 'Yearly',
        },
        datePicker: (date: string, time: string) => `${date} @ ${time}`,
        quickDate: (day: string, month: string, time: string) => `${day}/${month} @ ${time}`,
        loading: 'Loading...',
        yes: 'Yes',
        no: 'No'
    }
}

export default strings;