const loginStrings = {
    loginScreen: {
        emailLogin: 'Email',
        password: 'Password',
        passwordConfirm: 'Confirm Password',
        createAccount: 'Create Account',
        needAccountPt1: 'Need a business account?',
        needAccountPt2: 'Please contact our sales team at',
        needAccountPt3: 'support@lamppost.app',
        needAccountPt4: 'or',
        needAccountPt5: '(415) 967 - 0704',
        enterValidEmail: 'Enter a valid email',
        enterUniqueEmail: 'Enter a unique email',
        enterValidPassword: 'Enter a valid password',
        enterValidConfirmedPassword: 'Passwords do not match',
        passwordRequirements: {
            charMin: '* minimum 8 characters',
            uppercase: '* at least one uppercase letter',
            lowercase: '* at least one lowercase letter',
            number: '* at least one number',
            specialChar: '* at least one special character (@$!%*?&)',
        }
    }
}

export default loginStrings;