import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { SignedIn, SignedOut, SignInButton, SignOutButton } from '@clerk/clerk-react';
import { ReactComponent as LogoIcon } from '../../assets/icons/LogoIcon.svg';
import { LAMP_POST_COLORS } from '../../ui/constants';
import { NAVIGATION_IDS, NAV_LIST_DATA } from '../common/constants/navigation';
import styles from './Header.module.scss';
import Button, { BUTTON_VARIANTS } from '../../ui/button/Button';
import { useAppContext } from '../../contexts/AppContext';
import strings from '../../i18n/i18nStrings';
import LoginModal from '../loginModal/LoginModal';

const Header = () => {
    const { isSignedIn, logOut } = useAppContext();
    const navigate = useNavigate();
    // const location = useLocation();

    const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    const menuRef = useRef<any>(null);
    const buttonRef = useRef<any>(null);

    const onNavigation = (url: string) => {
        setIsNavMenuOpen(false);
        navigate(`/${url}`);
    }

    const onLoginPress = () => {
        if (isSignedIn) {
            // navigate(NAVIGATION_IDS.DASHBOARD);
        } else {
            // setIsLoginModalOpen(true);
        }
    }

    const toggleOpenMenu = () => {
        setIsNavMenuOpen(prevState => !prevState);
    }

    const handleOutsideClick = (event: { target: any; }) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            !buttonRef.current.contains(event.target)
        ) {
            setIsNavMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className={styles.headerContainer}>
            <div className={styles.header}>
                <div
                    onClick={() => navigate(`/${NAVIGATION_IDS.FRONTPAGE}`)}
                    className={styles.logo}
                >
                    <LogoIcon className={styles.logoImage} fill={LAMP_POST_COLORS.amber} />
                </div>
                <div
                    onClick={() => navigate(`/${NAVIGATION_IDS.DASHBOARD}`)}
                    className={styles.title}
                >
                    {strings.common.lampPost}
                </div>
                <SignedOut>
                    <SignInButton>
                        <div className={styles.loginButtonContainer}>
                            <Button onPress={onLoginPress}>
                                <span>{strings.landingScreen.login}</span>
                            </Button>
                        </div>
                    </SignInButton>
                </SignedOut>
                <SignedIn>
                    <div className={styles.menuContainer}>
                        <div ref={buttonRef}>
                            <Button
                                onPress={toggleOpenMenu}
                                roleVariant={BUTTON_VARIANTS.GHOST}
                            >
                                <div className={styles.menuButton}>
                                    <span />
                                    <span />
                                    <span />
                                </div>
                            </Button>
                        </div>
                        {
                            isNavMenuOpen && (
                                <div
                                    className={styles.menuList}
                                    ref={menuRef}
                                >
                                    {
                                        Object.values(NAV_LIST_DATA).map(navListItem => (
                                            <div
                                                key={navListItem.url}
                                                onClick={() => onNavigation(navListItem.url)}
                                                className={styles.menuItem}
                                            >
                                                <span>{navListItem.title}</span>
                                            </div>
                                        ))
                                    }
                                    <SignOutButton>
                                        <div className={styles.logoutButtonContainer}>
                                            <Button
                                                onPress={logOut}
                                                roleVariant={BUTTON_VARIANTS.DESTRUCTIVE}
                                            >
                                                <span>{strings.common.logOut}</span>
                                            </Button>
                                        </div>
                                    </SignOutButton>
                                </div>
                            )
                        }
                    </div>
                </SignedIn>
            </div>
            <LoginModal
                isOpen={isLoginModalOpen}
                onClose={() => setIsLoginModalOpen(false)}
            />
        </div>
    )
}

export default Header;