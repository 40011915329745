import { useState } from "react";
import PageLayout from "../common/PageLayout";
import Modal from "../../ui/modal/Modal";
import strings from "../../i18n/i18nStrings";

const LegalPage = () => {
    const [modalText, setModalText] = useState(null);

    return (
        <>
            <PageLayout>
                <div className="footer-section legal">
                    <h4>{strings.legal.legal}</h4>
                    <p>
                        <span onClick={() => setModalText(strings.legal.privacyPolicy)}>{strings.legal.privacyPolicy.title}</span>
                    </p>
                    <p>
                        <span onClick={() => setModalText(strings.legal.termsOfService)}>{strings.legal.termsOfService.title}</span>
                    </p>
                    <p>
                        <span onClick={() => setModalText(strings.legal.cookiePolicy)}>{strings.legal.cookiePolicy.title}</span>
                    </p>
                </div>
            </PageLayout>
            {
                modalText && (
                    <Modal
                        isOpen={!!modalText}
                        onClose={() => setModalText(null)}
                    >
                        <>
                            <p>{modalText.title}</p>
                            <p>{modalText.effectiveDate}</p>
                            {
                                Object.values(modalText.sections).map((modalSection: any) => (
                                    <div>
                                        <p>{modalSection.title}</p>
                                        {Object.values(modalSection.paragraphs).map((modalParagraph: string) => (
                                            <p>{modalParagraph}</p>
                                        ))}
                                    </div>
                                ))
                            }
                        </>
                    </Modal>
                )
            }
        </>
    )
}

export default LegalPage;