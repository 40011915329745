import styles from "./HomePage.module.scss";
import PageLayout from "../common/PageLayout";
import EventDisplayList from "../../components/eventDisplayList/EventDisplayList";
import EventDisplay from "../../components/eventDisplay/EventDisplay";
import { useEventsContext } from "../../contexts/EventsContext";

const HomePage = () => {
    const { viewingEvent } = useEventsContext();

    return (
        <PageLayout>
            <div className={styles.eventsContainer}>
                <div className={`${styles.eventsList} ${viewingEvent ? styles.selectedEventList : ''}`}>
                    <EventDisplayList />
                </div>
                <div className={`${styles.eventsDisplay} ${viewingEvent ? styles.selectedEventDisplay : ''}`}>
                    <EventDisplay />
                </div>
            </div>
        </PageLayout>
    );
}

export default HomePage;