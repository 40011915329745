import { useFilterContext } from "../../../contexts/FilterContext";
import strings from "../../../i18n/i18nStrings"
import Button, { BUTTON_VARIANTS } from "../../../ui/button/Button";
import styles from "./EmptyDisplayList.module.scss";

const EmptyDisplayList = ({
    onCtaClick
} : {
    onCtaClick: () => void;
}) => {
    const {
        filtersAreApplied,
        clearFilters
    } = useFilterContext();

    return (
        <div className={styles.emptyDisplayContainer}>
            <p className={styles.mainText}>
                {strings.eventDetailsScreen.emptyList}
            </p>
            <Button
                onPress={onCtaClick}
                roleVariant={BUTTON_VARIANTS.GHOST}
            >
                <span>{strings.eventDetailsScreen.emptyListCta}</span>
            </Button>
            {
                filtersAreApplied && (
                    <Button
                        onPress={clearFilters}
                        roleVariant={BUTTON_VARIANTS.GHOST}
                    >
                        <span>{strings.eventDetailsScreen.emptyListRemoveFilters}</span>
                    </Button>
                )
            }
        </div>
    )
}

export default EmptyDisplayList;