import {
    createContext,
    useContext,
    useEffect,
    useState
} from 'react';
import { FilterType } from '../types/FilterType';
import {
    FILTER_COSTS,
    FILTER_DISTANCE,
    FILTER_TAGS
} from '../components/filters/filters.constants';
import { useAppContext } from './AppContext';

type FilterContextValues = {
    filters: FilterType;
    clearFilters: () => void;
    applyFilters: (newFilters: FilterType) => void;
    filtersAreApplied: boolean;
}

const DEFAULT_FILTERS = {
    name: '',
    location: null,
    distance: [FILTER_DISTANCE.tenMiles],
    date: new Date().getTime(),
    cost: [FILTER_COSTS.any],
    tags: [],
    ages: [],
    groups: [],
}

export const FilterContext = createContext({} as FilterContextValues);

export const useFilterContext = () => useContext(FilterContext);

export const FilterContextProvider = ({ children }) => {
    const {
        userInfo,
        isSignedIn
    } = useAppContext();
    const [filters, setFilters] = useState<FilterType>(DEFAULT_FILTERS);
    const [filtersAreApplied, setFiltersAreApplied] = useState(false);

    useEffect(() => {
        if (isSignedIn) {
            const userInitialTags = userInfo?.interests?.map(interest => Object.values(FILTER_TAGS).find(tag => tag.id === interest)) || [];
            setFilters({
                ...DEFAULT_FILTERS,
                tags: userInitialTags
            });
            setFiltersAreApplied(!!userInitialTags)
        }
    }, [isSignedIn, userInfo?.interests]);

    const clearFilters = () => {
        setFiltersAreApplied(false);
        setFilters(DEFAULT_FILTERS);
    }

    const applyFilters = (newFilters: FilterType) => {
        setFiltersAreApplied(true);
        setFilters(newFilters);
    }

    return (
        <FilterContext.Provider
            value={{
                filters,
                clearFilters,
                applyFilters,
                filtersAreApplied,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
}