const eventStrings = {
    eventDetailsScreen: {
        eventList: 'Event List',
        upcomingEvents: 'Upcoming Events',
        pastEvents: 'Past Events',
        backtoEvents: 'Back to Events',
        additionalAttendees: (count: number) => `+${count} Others`,
        attendees: 'Attendees',
        cost: (amt: string) => `Cost: ${amt}`,
        free: 'Free',
        emptyList: 'Looks like nothing’s planned yet.',
        emptyListCta: 'Why not start something?',
        emptyListRemoveFilters: 'Or try tweaking the filters to discover more!',
        join: 'Join Event',
        leave: 'Exit Event',
        delete: 'Delete',
        edit: 'Edit',
        emptyEvent: {
            description1: 'The spotlight’s on you.',
            description2: 'Choose an event!'
        }
    }
}

export default eventStrings;