const strings = {
    faq: {
        generalQuestions: {
            title: 'General Questions',
            questions: {
                q1: {
                    question: 'Q: What is LampPost?',
                    answer: 'A: LampPost is a community engagement platform that allows users to stay updated with and organize local events. Our goal is to foster stronger, more connected communities.',
                },
                q2: {
                    question: 'Q: How does LampPost work?',
                    answer: 'A: LampPost users can create and participate in events in their local area. The platform connects community members with each other to promote local events.',
                },
            }
        },
        accountAndProfile: {
            title: 'Account and Profile',
            questions: {
                q1: {
                    question: 'Q: How do I create an account on LampPost?',
                    answer: 'A: To create an account, click on the "Sign Up" button on the homepage, fill in your details, and follow the prompts to verify your email address.',
                },
                q2: {
                    question: 'Q: How can I edit my profile information?',
                    answer: 'A: Log in to your account, go to your profile page, and click on the "Edit Profile" button. Here you can update your personal information, profile picture, and other details.',
                },
                q3: {
                    question: 'Q: I forgot my password. How can I reset it?',
                    answer: 'A: Click on the "Forgot Password" link on the login page, enter your email address, and follow the instructions to reset your password.',
                },
            }
        },
        reportingIssues: {
            title: 'Reporting Issues',
            questions: {
                q1: {
                    question: 'Q: How do I report a local issue?',
                    answer: 'A: Click on the "Report Issue" button on the homepage or in the app, provide the necessary details (such as location, description, and photos), and submit the report. Your report will be sent to the appropriate local authorities.',
                },
                q2: {
                    question: 'Q: What types of issues can I report?',
                    answer: 'A: You can report various local issues such as potholes, broken street lights, graffiti, public safety concerns, and more.',
                },
                q3: {
                    question: 'Q: How can I track the status of my reported issue?',
                    answer: 'A: Go to your profile and click on "My Reports" to see the status of your submitted issues. You will also receive notifications about updates and resolutions.',
                },
            }
        },
        events: {
            title: 'Events',
            questions: {
                q1: {
                    question: 'Q: How do I find local events on LampPost?',
                    answer: 'A: Navigate to the "Events" section from the homepage or app menu. You can browse upcoming events by category, date, or location.',
                },
                q2: {
                    question: 'Q: How do I create an event?',
                    answer: 'A: Click on the "Create Event" button, fill in the event details (such as title, date, time, location, and description), and submit. Your event will be visible to the community once approved.',
                },
                q3: {
                    question: 'Q: Can I edit or cancel an event I created?',
                    answer: 'A: Yes, go to your profile, click on "My Events," select the event you want to edit or cancel, and make the necessary changes.',
                },
            }
        },
        communityGuidelines: {
            title: 'Community Guidelines',
            questions: {
                q1: {
                    question: 'Q: What are the community guidelines for using LampPost?',
                    answer: 'A: Our community guidelines include being respectful, not posting offensive content, and following local laws and regulations. For more details, please visit our Community Guidelines page.',
                },
                q2: {
                    question: 'Q: How can I report inappropriate content or behavior?',
                    answer: 'A: Click on the "Report" button next to the content or user profile, provide details about the issue, and submit. Our team will review the report and take appropriate action.',
                },
            }
        },
        technicalSupport: {
            title: 'Technical Support',
            questions: {
                q1: {
                    question: 'Q: The app/website isn`t working properly. What should I do?',
                    answer: 'A: Try refreshing the page or restarting the app. If the issue persists, check our Help Center for troubleshooting tips or contact our support team via the "Contact Us" page.',
                },
                q2: {
                    question: 'Q: How do I update the LampPost app?',
                    answer: 'A: Go to the App Store or Google Play Store, search for LampPost, and click on the "Update" button if an update is available.',
                },
            }
        },
        privacyAndSecurity: {
            title: 'Privacy and Security',
            questions: {
                q1: {
                    question: 'Q: How does LampPost protect my privacy?',
                    answer: 'A: We take your privacy seriously and use industry-standard measures to protect your data. For more details, please review our Privacy Policy.',
                },
                q2: {
                    question: 'Q: Can I delete my account?',
                    answer: 'A: Yes, you can delete your account by going to your profile settings and selecting the "Delete Account" option. Please note that this action is irreversible.',
                },
            }
        },
        miscellaneous: {
            title: 'Miscellaneous',
            questions: {
                q1: {
                    question: 'Q: How do I contact LampPost support?',
                    answer: 'A: You can contact our support team by visiting the "Contact Us" page and filling out the form, or by emailing support@lamppost.app.',
                },
                q2: {
                    question: 'Q: Can businesses or organizations join LampPost?',
                    answer: 'A: Yes, businesses and organizations can create profiles to engage with the community, post events, and more. Visit our "Business Solutions" page for more information.',
                },
                q3: {
                    question: 'Q: How do I give feedback or suggestions?',
                    answer: 'A: We welcome your feedback! Please visit the "Feedback" section in the app or on our website to share your thoughts and suggestions.',
                },
            }
        }
    }
};

export default strings;