import { ReactElement, createContext, useContext, useEffect, useState } from 'react';
import { lampPostFetch } from '../utils/fetchHelpers';
import {
    CREATE_EVENT,
    DELETE_EVENT,
    UPDATE_EVENT,
    GET_EVENTS,
} from '../components/common/constants/endpoints';
import { useAppContext } from './AppContext';
import { EventType } from '../types/EventType';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFilterContext } from './FilterContext';

type EventsContextValues = {
    events: EventType[];
    selectedEvent: string;
    eventsLoading: boolean;
    createOrEditEvent: (newEvent: object, isAttending: boolean, onSuccess?: () => void) => void;
    deleteEvent: (eventId: string) => void;
    onSelectEvent: (eventId: string) => void;
    onDeselectEvent: () => void;
    fetchEvents: () => void;
    viewingEvent: boolean;
}

export const EventsContext = createContext({} as EventsContextValues);

export const useEventsContext = () => useContext(EventsContext);

export const EventsContextProvider = ({ children }: { children: ReactElement }) => {
    const {
        userInfo,
        userLocation,
        getUpdatedUser
    } = useAppContext();
    const { filters } = useFilterContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [events, setEvents] = useState([]);
    const [eventsLoading, setEventsLoading] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<string>(null);

    const fetchEvents = () => {
        setEventsLoading(true);

        let locationValue;

        if (filters?.location) {
            locationValue = filters.location
        } else {
            locationValue = {
                lat: userLocation?.latitude,
                lng: userLocation?.longitude,
            }
        }

        const filterQueryContents = [`locLat=${locationValue.lat}&locLng=${locationValue.lng}`];

        Object.keys(filters).forEach((filterKey) => {
            const filterValue = filters[filterKey];

            if (filterKey === 'cost' || filterKey === 'distance' || filterKey === 'tags') {
                filterValue.forEach((filterArrayValue) => filterQueryContents.push(`${filterKey}=${filterArrayValue.id}`));
            } else if (filterKey !== 'location') {
                filterQueryContents.push(`${filterKey}=${filterValue || null}`);
            }
        });

        lampPostFetch(
            `${GET_EVENTS}?${filterQueryContents.join('&')}`,
            {},
            async (result) => {
                setEventsLoading(false);

                if (result && result?.length) {
                    setEvents(result);

                    const specifiedEventId = searchParams.get('eventId');

                    if (specifiedEventId) {
                        setSelectedEvent(specifiedEventId);
                    } else {
                        setSelectedEvent(result[0]._id);
                    }
                } else {
                    setEvents([]);
                }
            }
        );
    }

    const createOrEditEvent = (eventInfo: any, isAttending: boolean = true, onSuccess?: () => void) => {
        const locationData = eventInfo.location.geometry ? {
            id: eventInfo.location.place_id,
            name: eventInfo.location.name,
            address: eventInfo.location.formatted_address,
            lat: eventInfo.location.geometry.location.lat(),
            lng: eventInfo.location.geometry.location.lng(),
        } : eventInfo.location;

        const fetchOptions: RequestInit = {
            headers: { "Content-type": "application/json", },
            method: eventInfo._id ? "PATCH" : "POST",
            body: JSON.stringify({
                ...eventInfo,
                attendees: isAttending ? [userInfo?._id] : [],
                creator: userInfo?._id || -1,
                location: locationData,
                isAttending,
            })
        };

        lampPostFetch(
            eventInfo._id ? UPDATE_EVENT(eventInfo._id) : CREATE_EVENT,
            fetchOptions,
            (result: { _id: any; }) => {
                if (result) {
                    if (userInfo) {
                        getUpdatedUser();
                    }

                    if (onSuccess) {
                        onSuccess();
                    }
                }
            }
        );
    }

    const deleteEvent = (eventId: string) => {
        lampPostFetch(DELETE_EVENT(eventId), { method: "DELETE" });
    }

    const onSelectEvent = (eventId: string) => {
        const newParams = new URLSearchParams(searchParams);
        newParams.set('eventId', eventId);
        setSearchParams(newParams);
        navigate(`?${newParams.toString()}`);
        setSelectedEvent(eventId);
    }

    const onDeselectEvent = () => {
        const newParams = new URLSearchParams(searchParams);
        newParams.delete('eventId');
        setSearchParams(newParams);
        navigate(`?${newParams.toString()}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fetchEvents, [filters, userLocation?.latitude, userLocation?.longitude]);

    return (
        <EventsContext.Provider
            value={{
                events,
                selectedEvent,
                eventsLoading,
                fetchEvents,
                createOrEditEvent,
                deleteEvent,
                onSelectEvent,
                onDeselectEvent,
                viewingEvent: !!searchParams.get('eventId')
            }}
        >
            {children}
        </EventsContext.Provider>
    )
}