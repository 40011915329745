import { ReactElement, createContext, useContext, useEffect, useState } from 'react';
import { lampPostFetch } from '../utils/fetchHelpers';
import {
    GET_SELF,
    GET_USER,
    UPDATE_USER
} from '../components/common/constants/endpoints';
import { GET_SERVER } from '../components/common/constants/servers';

type AppContextValues = {
    userInfo: any;
    userLocation: GeolocationCoordinates;
    isSignedIn: boolean;
    signIn: (email: string, password: string, callback?: () => void) => void;
    logOut: () => void;
    updateUserInfo: (changedUserInfo: any, callback?: () => void) => void;
    getUpdatedUser: () => void;
    server: string;
}

export const AppContext = createContext({} as AppContextValues);

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }: { children: ReactElement }) => {
    const [userInfo, setUserInfo] = useState<any>(
        window.sessionStorage.getItem('userInfo') ?
            JSON.parse(window.sessionStorage.getItem('userInfo') as string) :
            null
    );
    const [isSignedIn, setIsSignedIn] = useState(!!userInfo);

    const [userLocation, setUserLocation] = useState(null);

    const signIn = (
        enteredEmail: string,
        enteredPassword: string,
        callback?: () => void
    ) => {
        if (enteredEmail && enteredPassword) {
            lampPostFetch(
                GET_USER(enteredEmail, enteredPassword),
                { method: "GET" },
                (result: any) => {
                    if (result) {
                        setUserInfo({ ...result });
                        setIsSignedIn(true);
                        window.sessionStorage.setItem('userInfo', JSON.stringify(result));

                        if (callback) {
                            callback();
                        }
                    }
                }
            );
        }
    }

    const logOut = () => {
        setIsSignedIn(false);
        setUserInfo(null)
        window.sessionStorage.setItem('userInfo', '');
    }

    const getUpdatedUser = () => {
        lampPostFetch(
            GET_SELF(userInfo?._id),
            { method: "GET" },
            (result) => {
                if (result) {
                    setUserInfo({ ...result });
                }
            }
        );
    }

    const updateUserInfo = (changedUserInfo: any, callback?: () => void) => {
        lampPostFetch(
            UPDATE_USER(userInfo?._id),
            {
                method: "PATCH",
                body: JSON.stringify({
                    ...userInfo,
                    ...changedUserInfo,
                    completedAccount: true
                })
            },
            (result: any) => {
                if (result) {
                    setUserInfo({ ...result });

                    if (callback) {
                        callback();
                    }
                }
            }
        );
    }

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    console.log(error.message);
                }
            );
        } else {
            console.log('Geolocation is not supported by your browser.');
        }
    }, []);

    return (
        <AppContext.Provider
            value={{
                userInfo,
                userLocation,
                isSignedIn,
                signIn,
                logOut,
                updateUserInfo,
                getUpdatedUser,
                server: GET_SERVER()
            }}
        >
            {children}
        </AppContext.Provider>
    )
}