import strings from "../../../i18n/i18nStrings";
import styles from "./EmptyDisplay.module.scss";

const EmptyDisplay = () => {

    return (
        <div className={styles.emptyContainer}>
            <div>
                {strings.eventDetailsScreen.emptyEvent.description1}
            </div>
            <div>
                {strings.eventDetailsScreen.emptyEvent.description2}
            </div>
        </div>
    )
}

export default EmptyDisplay;