import { ReactElement, useEffect, useRef, useState } from 'react';
import styles from './PageLayout.module.scss';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

const PageLayout = ({ children }: { children: ReactElement }) => {
    const headerRef = useRef(null);
    const footerRef = useRef(null);

    const [mainPageContainerOffset, setMainPageContainerOffset] = useState(0);

    useEffect(() => {
        const filterContainerHeight = headerRef?.current?.getBoundingClientRect()?.height || 0;
        const createContainerHeight = footerRef?.current?.getBoundingClientRect()?.height || 0;
        setMainPageContainerOffset(filterContainerHeight + createContainerHeight);
    }, [headerRef, footerRef]);

    return (
        <>
            <div ref={headerRef}>
                <Header /></div>
            <div
                className={styles.childContainer}
                style={{ height: `calc(100dvh - ${mainPageContainerOffset}px` }}
            >
                <div className={styles.childContent}>
                    {children}
                </div>
            </div>
            <div ref={footerRef}>
                <Footer />
            </div>
        </>
    )
}

export default PageLayout;