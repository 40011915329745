import { useEventsContext } from "../../../contexts/EventsContext";
import strings from "../../../i18n/i18nStrings";
import { EventType } from "../../../types/EventType";
import { ReactComponent as LogoIcon } from '../../../assets/icons/LogoIcon.svg';
import styles from "./EventDisplayListItem.module.scss";

const EventDisplayListItem = ({
    event,
}: {
    event: EventType
}) => {
    const {
        onSelectEvent,
        selectedEvent,
        viewingEvent
    } = useEventsContext();

    return (
        <div
            key={event._id}
            className={`
            ${styles.eventContainer}
            ${event._id === selectedEvent ? styles.selectedEvent : ''}
            ${viewingEvent ? styles.viewingEvent : ''}
        `}
            onClick={() => onSelectEvent(event._id)}
        >
            {
                event.images.length ? (
                    <img
                        src={event.images.length ? event.images[0] : ''}
                        className={styles.imageContainer}
                        alt="User Upload"
                    />
                ) : (
                    <div className={`${styles.imageContainer} ${styles.logo}`}>
                        <LogoIcon />
                    </div>
                )
            }
            <div className={styles.eventInfo}>
                <div className={styles.title}>
                    {event.name}
                </div>
                <div className={styles.location}>
                    {event.location.name}
                </div>
                <div className={styles.time}>
                    {
                        strings.common.quickDate(
                            new Date(event.date).getMonth().toString(),
                            new Date(event.date).getDate().toString(),
                            new Date(event.date).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default EventDisplayListItem;