import commonStrings from './screens/common';
import landingStrings from './screens/landingScreen';
import loginStrings from './screens/loginScreen';
import homeStrings from './screens/homeScreen';
import faqStrings from './screens/faqScreen';
import aboutStrings from './screens/aboutUsScreen';
import legalStrings from './screens/legalScreen';
import companyStrings from './screens/companyScreen';
import eventStrings from './screens/eventDetailsScreen';
import userProfileStrings from './screens/userProfileScreen';
import messagesStrings from './screens/messagesScreen';
import createEventStrings from './screens/createEventScreen';
import settingsStrings from './screens/settingsScreen';
import notificationsStrings from './screens/notificationsScreen';
import myEventsStrings from './screens/myEventsScreen';
import filterStrings from './components/filters';
import locationAutocompleteStrings from './components/locationAutocomplete';
import demographicsStrings from './components/demographics';

const strings = {
    ...commonStrings,
    ...landingStrings,
    ...loginStrings,
    ...homeStrings,
    ...faqStrings,
    ...aboutStrings,
    ...legalStrings,
    ...companyStrings,
    ...eventStrings,
    ...userProfileStrings,
    ...messagesStrings,
    ...createEventStrings,
    ...settingsStrings,
    ...notificationsStrings,
    ...myEventsStrings,
    ...filterStrings,
    ...locationAutocompleteStrings,
    ...demographicsStrings,
}

export default strings;