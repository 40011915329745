import { useEffect, useRef, useState } from "react";
import { useEventsContext } from "../../contexts/EventsContext";
import strings from "../../i18n/i18nStrings";
import Button, { BUTTON_VARIANTS } from "../../ui/button/Button";
import PhotoRoll from "../photoRoll/PhotoRoll";
import styles from "./EventDisplay.module.scss";
import { lampPostFetch } from "../../utils/fetchHelpers";
import { GET_EVENT } from "../common/constants/endpoints";
import { EventType } from "../../types/EventType";
import BackHeader from "../mobile/backHeader/BackHeader";
import Loader from "../loader/Loader";
import EmptyDisplay from "./emptyDisplay/EmptyDisplay";
import { useFilterContext } from "../../contexts/FilterContext";
import { useAppContext } from "../../contexts/AppContext";
// import { FILTER_TAGS } from "../filters/filters.constants";
// import InterestChips from "../interestChips/InterestChips";

const EventDisplay = () => {
    const {
        eventsLoading,
        selectedEvent,
        onDeselectEvent
    } = useEventsContext();
    const { filters } = useFilterContext();
    const { userLocation } = useAppContext();

    const [eventData, setEventData] = useState<EventType>(null);
    const [eventDataLoading, setEventDataLoading] = useState(false);

    const eventContainer = useRef(null);

    useEffect(() => {
        if (selectedEvent) {
            setEventData(null);
            setEventDataLoading(true);

            let locationValue;

            if (filters?.location) {
                locationValue = filters.location
            } else {
                locationValue = {
                    lat: userLocation?.latitude,
                    lng: userLocation?.longitude,
                }
            }

            lampPostFetch(
                `${GET_EVENT(selectedEvent)}?locLat=${locationValue.lat}&locLng=${locationValue.lng}`,
                {},
                (newEventData: EventType) => {
                    setEventDataLoading(false);
                    setEventData(newEventData);
                }
            );
        }

        if (eventContainer.current) {
            eventContainer.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, [filters.location, selectedEvent, userLocation?.latitude, userLocation?.longitude]);

    const formatAddress = () => {
        const address = eventData.location.address || eventData.location.formatted_address;
        const splitAddress = address.split(',');

        const separatedAddress = [
            splitAddress[0],
            splitAddress[1],
            splitAddress[2],
        ];

        if (parseFloat(eventData.distance) > 0) {
            separatedAddress.push(`(${eventData.distance})`)
        }

        return separatedAddress;
    }

    return eventsLoading ? (
        <Loader />
    ) : (
        <div className={styles.eventDisplayContainer}>
            {eventData ? (
                <>
                    <div className={styles.eventPreviewContainer}>
                        <BackHeader
                            onBack={onDeselectEvent}
                            backText={strings.eventDetailsScreen.backtoEvents}
                        />
                        <div className={styles.eventPreview} ref={eventContainer}>
                            {
                                eventData?.images?.length ? <PhotoRoll photoUrls={eventData.images} /> : null
                            }
                            <div className={styles.infoContainer}>
                                <div className={styles.sizedInfo}>
                                    <div className={styles.infoItem}>
                                        <p className={styles.eventName}>{eventData.name}</p>
                                    </div>
                                    <div className={styles.infoItem}>
                                        <p className={styles.eventLocation}>{eventData.location.name}</p>
                                    </div>
                                    <div className={styles.infoItem}>
                                        {formatAddress().map((addressPiece, idx) => (
                                            <p
                                                key={idx}
                                                className={styles.locationText}
                                            >
                                                {addressPiece}
                                            </p>
                                        ))}
                                    </div>
                                    <div className={styles.infoItem}>
                                        <p className={styles.infoItemText}>
                                            {
                                                strings.common.datePicker(
                                                    new Date(eventData.date).toLocaleDateString(),
                                                    new Date(eventData.date).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })
                                                )
                                            }
                                        </p>
                                    </div>
                                    <div className={`${styles.attendeeList} ${styles.infoItem}`}>
                                        <div className={styles.attendeeImagesContainer}>
                                            {/* {
                                            eventData.attendees.slice(0, 3).map((attendee: { attendeeImage: string | undefined; }, idx: Key | null | undefined) => (
                                                <div key={idx}>
                                                    <img
                                                        src={attendee.attendeeImage}
                                                        className={styles.attendeeImage}
                                                        alt="Attendee"
                                                    />
                                                </div>
                                            ))
                                        } */}
                                        </div>
                                        {
                                            eventData.attendees.length > 3 ? (
                                                <p className={styles.infoItemText}>
                                                    {strings.eventDetailsScreen.additionalAttendees(eventData.attendees.length - 3)}
                                                </p>
                                            ) : null
                                        }
                                    </div>
                                    <div className={eventData?.tags?.length ? styles.infoItem : ''}>
                                        <p className={styles.infoItemText}>
                                            {strings.eventDetailsScreen.cost(
                                                eventData.cost > 0 ? eventData.cost.toFixed(2) : strings.eventDetailsScreen.free
                                            )}
                                        </p>
                                    </div>
                                    {/* {
                                    eventData?.tags?.length ? (
                                        <InterestChips
                                            interests={eventData.tags.map((tag: string) => Object.values(FILTER_TAGS).find(filterTag => filterTag.id === parseInt(tag)))}
                                        />
                                    ) : null
                                } */}
                                </div>
                            </div>
                            <p>{eventData.description}</p>
                        </div>
                        {
                            new Date().getTime() < eventData.date && (
                                <div className={styles.rsvpContainer}>
                                    <Button
                                        roleVariant={BUTTON_VARIANTS.CTA}
                                        onPress={() => { }}
                                    >
                                        <span>{strings.eventDetailsScreen.join}</span>
                                    </Button>
                                </div>
                            )
                        }
                    </div>
                </>
            ) : (
                <div>
                    {eventDataLoading ? <div>Loading</div> : <EmptyDisplay />}
                </div>
            )}
        </div>
    )
}

export default EventDisplay;