import strings from "../../../i18n/i18nStrings"

export const NAVIGATION_IDS = {
    LOGIN: "login",
    FRONTPAGE: "front-page",
    DASHBOARD: "dashboard",
    EVENTS: "events",
    ANALYTICS: "analytics",
    SETTINGS: "settings",
    ABOUT: "about-us",
    FAQ: "faq",
    LEGAL: "legal"
}

export const NAV_LIST_DATA = {
    EVENTS: {
        title: strings.homeScreen.eventList.title,
        description: strings.homeScreen.eventList.description,
        url: NAVIGATION_IDS.EVENTS,
    },
    ANALYTICS: {
        title: strings.homeScreen.analytics.title,
        description: strings.homeScreen.analytics.description,
        url: NAVIGATION_IDS.ANALYTICS,
    },
    SETTINGS: {
        title: strings.homeScreen.settings.title,
        description: strings.homeScreen.settings.description,
        url: NAVIGATION_IDS.SETTINGS,
    }
}