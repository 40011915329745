const strings = {
    aboutUs: {
        title: 'About Us',
        sections: {
            section1: {
                title: 'Welcome to LampPost!',
                paragraphs: {
                    paragraph1: 'At LampPost, we believe in the power of community and the importance of local connections. Our platform is dedicated to fostering stronger, more connected communities by providing a space where residents can discover, share, and participate in local events.',
                }
            },
            section2: {
                title: 'Our Mission',
                paragraphs: {
                    paragraph1: 'Our mission is to bring people together through shared experiences and events. We aim to provide the tools and resources necessary for residents to engage with one another, create lasting connections, and enrich their local communities.',
                }
            },
            section3: {
                title: 'What We Do',
                paragraphs: {
                    paragraph1: 'Discover Local Events: LampPost is your go-to platform for finding events happening in your neighborhood. From block parties and farmers markets to local concerts and community workshops, we make it easy to discover what’s going on nearby.',
                    paragraph2: 'Share Your Events: Have an event you want to promote? LampPost allows you to easily create and share events with your community. Whether you`re organizing a garage sale, a book club, or a community cleanup, our platform helps you reach your local audience.',
                    paragraph3: 'Connect with Neighbors: LampPost is designed to help you interact with your neighbors. Join discussions, share event photos, and connect with others who share your interests. Our platform encourages meaningful interactions and helps build a sense of community.',
                }
            },
            section4: {
                title: 'Our Values',
                paragraphs: {
                    paragraph1: 'Community: We are committed to building and nurturing vibrant, active communities. We believe that when people come together, great things happen.',
                    paragraph2: 'Connection: We value the connections that make communities strong. LampPost is designed to facilitate interactions and foster relationships among neighbors.',
                    paragraph3: 'Empowerment: Our platform is built to empower individuals to take an active role in their communities. We provide the tools and resources necessary for residents to organize and participate in local events.',
                    paragraph4: 'Simplicity: We aim to make discovering and sharing local events as simple and user-friendly as possible. Our intuitive platform is designed with the user in mind.',
                }
            },
            section5: {
                title: 'Join Us',
                paragraphs: {
                    paragraph1: 'Be a part of the change you want to see in your community. Join LampPost today and help us create stronger, more connected neighborhoods through the power of local events.',
                    paragraph2: 'We are hiring for the following positions:',
                }
            },
            section6: {
                title: 'Contact Us',
                paragraphs: {
                    paragraph1: 'We love to hear from our users! If you have any questions, suggestions, or feedback, please don`t hesitate to reach out to us at support@lamppost.app.',
                    paragraph2: 'Together, we can light up our communities.'
                }
            },
        }
    }
};

export default strings;