import strings from "../../i18n/i18nStrings";
import PageLayout from "../common/PageLayout";
import styles from "./FaqPage.module.scss";

const FaqPage = () => (
    <PageLayout>
        <div className={styles.faqContainer}>
            {
                Object.values(strings.faq).map((faqInfo) => (
                    <div>
                        <p className={styles.faqHeader}>{faqInfo.title}</p>
                        <div className={styles.faqQAContainer}>
                            {Object.values(faqInfo.questions).map(faqQuestion => (
                                <div>
                                    <p className={styles.faqQuestion}>{faqQuestion.question}</p>
                                    <p>{faqQuestion.answer}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                ))
            }
        </div>
    </PageLayout>
);

export default FaqPage;